.about-us {
	.container-slider-news {
		@media (max-width: 1000px) {
			padding: 200px 0 256px;
		}

		@media (max-width: 768px) {
			padding: 0 0 256px;
		}
	}
}

.container-slider-news {
	background: #dae2ed80;
	padding: 100px 0;
  	padding-bottom: 256px;

	@media (max-width: 560px) {
  		padding-bottom: 249px;
	}

	.container-page {
		padding-right: 0;
		max-width: initial;

		@media (max-width: 560px) {
			padding-right: 30px;
		}
	}

	/*Title de la seccion con anchor*/
	.container-title {
		display: flex;
		width: 100%;
		align-items: center;
		margin-bottom: 50.5px;
		padding-right: 80px;

		@media (max-width: 560px) {
			margin-bottom: 45px;
			padding-right: 0;
		}

		.title {
			display: flex;
			flex-wrap: wrap;
			font-family: 'Archivo';
			font-style: normal;
			font-weight: 700;
			font-size: 36px;
			line-height: 43px;
			color: #212e5e;

			@media (max-width: 560px) {
				font-family: 'Archivo';
				font-style: normal;
				font-weight: 700;
				font-size: 30px;
				line-height: 36px;
			}

			.small-text {
				display: block;
				width: 100%;
				font-family: 'Open Sans';
				font-style: normal;
				font-weight: 600;
				font-size: 18px;
				line-height: 24px;
				color: #E75E24;
				margin-bottom: 20px;
				text-transform: uppercase;

				@media (max-width: 560px) {
					font-family: 'Open Sans';
					font-style: normal;
					font-weight: 600;
					font-size: 15px;
					margin-bottom: 10px;
					line-height: 20px;
				}
			}
		}

		.btn-orange {
			min-width: 153px;
			height: 56px;
			background: linear-gradient(270deg, #E75E24 0%, #F9893D 100%);
			border-radius: 30px;
			font-family: 'Archivo';
			font-style: normal;
			font-weight: 500;
			font-size: 15px;
			line-height: 16px;
			display: flex;
			justify-content: center;
			align-items: center;
			color: #fff;
			margin-left: auto;

			&:hover {
				background: linear-gradient(270deg, #F9893D 0%, #F9893D 100%);
			}

			@media (max-width: 560px) {
				display: none;
			}
		}
	}

	.tns-outer {
		position: relative;

		.tns-nav {
			position: absolute;
			bottom: -70px;
      width: 100%;
      display: flex;
      justify-content: center;

			@media (max-width: 560px) {
				bottom: -12px;
				width: 100%;
				display: flex;
				justify-content: center;
			}

			button {
				width: 14px;
				height: 14px;
				background: #DAE2ED;
				margin-right: 10px;
				border-radius: 50%;
				transition: all 0.5s;

				&:last-child {
					margin-right: 0;
				}

				&.tns-nav-active {
					background: #E75E24;
				}

				&:hover {
					background: #f9893d;
				}
			}
		}
	}

	.slider-news {
		.item-slider {
			height: 541px;
			box-shadow: 0px 5px 20px rgba(45, 83, 151, 0.15);

			@media (max-width: 560px) {
				box-shadow: initial;
				max-height: initial;
			}

			.img-item {
				height: 312px;
				object-fit: cover;

				@media (max-width: 768px) {
					height: 280px;
				}
			}

			.container-text {
				padding: 35px;
				background: #fff;
				position: relative;
				height: 100%;
				max-height: 229px;
				display: flex;
				flex-direction: column;

				.label {
					min-width: 160px;
					height: 46px;
					background: #2d5397;
					border-radius: 23px 0px 0px 23px;
					font-family: 'Open Sans';
					font-style: normal;
					font-weight: 400;
					font-size: 16px;
					line-height: 26px;
					color: #fff;
					display: flex;
					align-items: center;
					justify-content: center;
					position: absolute;
					top: calc(-46px / 2);
					right: 0;
					padding: 0 20px;
				}

				.text-title {
					font-family: 'Archivo';
					font-style: normal;
					font-weight: 600;
					font-size: 24px;
					line-height: 30px;
					color: #212E5E;
					margin-bottom: 10px;
					display: -webkit-box;
					-webkit-line-clamp: 2;
					-webkit-box-orient: vertical;
					overflow: hidden;

					@media (max-width: 560px) {
						font-size: 20px;
						line-height: 26px;
					}
				}

				.text-description {
					font-family: 'Open Sans';
					font-style: normal;
					font-weight: 400;
					font-size: 16px;
					line-height: 26px;
					color: #8D9BAA;
					margin-bottom: 15px;
					display: -webkit-box;
					-webkit-line-clamp: 3;
					-webkit-box-orient: vertical;
					overflow: hidden;

					@media (max-width: 560px) {
						font-size: 15px;
						margin-bottom: 0;
						line-height: 22px;
					}
				}

				.text-link {
					align-items: center;
					display: flex;
					font-family: 'Open Sans';
					font-style: normal;
					font-weight: 600;
					font-size: 16px;
					line-height: 26px;
					color: #E75E24;
					width: max-content;
					margin-top: auto;

					svg {
						margin-left: 11px;
						border-radius: 50%;
						transition: all 0.5s;

						path {
						  transition: all 0.5s;
						  stroke: #E75E24;
						}
					}

					&:hover {
						svg {
						  background: #F9893D;

						  path {
							stroke: #fff;
						  }
						}
					  }
				}
			}
		}
	}

	.container-arrows {
		display: flex;
		margin-top: 30px;
		width: 100%;
		justify-content: center;
		padding-right: 80px;

		@media (max-width: 560px) {
			display: none;
		}

		.arrow {
			width: 62px;
			height: 62px;
			border-radius: 50%;
			background: linear-gradient(270deg, #E75E24 0%, #F9893D 100%);
			cursor: pointer;
			display: flex;
			align-items: center;
			justify-content: center;

			&:hover {
				background: linear-gradient(270deg, #F9893D 0%, #F9893D 100%);
				background: #F9893D;
			}

			&.left {
				margin-left: 70px;
			}

			&.right {
				margin-left: 140px;
			}
		}
	}

	.anchor-mobile {
		width: 170px;
		height: 56px;
		background: linear-gradient(270deg, #E75E24 0%, #F9893D 100%);
		border-radius: 30px;
		font-family: 'Archivo';
		font-style: normal;
		font-weight: 500;
		font-size: 15px;
		line-height: 16px;
		display: flex;
		justify-content: center;
		align-items: center;
		color: #fff;
		transition: all 0.5s;
		margin: auto;
		margin-top: 57px;

		&:hover {
			background: linear-gradient(270deg, #F9893D 0%, #F9893D 100%);
		}

		@media (min-width: 560px) {
			display: none;
		}
	}
}
